import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment';
import classes from "../../AddTalentForm.module.css";
import FormGreyWrapper from "../../../FormGreyWrappers/FormGreyWrapper/FormGreyWrapper";
import TalentAddRow from "./component/TalentAddRow/TalentAddRow";
import { triggerAutosurfaced } from "../../../../../utils/services/turboPlus2RestCalls";
import { showToastError, showToastSuccess } from "../../../../../utils/toastMessages";

const handleTriggerAutosurfacedButtonClick = (roleId) => {
    triggerAutosurfaced(roleId).then(
        response => {
            showToastSuccess(response.message || "Successfully triggered autosurfaced.");
        }
    ).catch(
        error => {
            const message = error.message ? error.message : "Error triggering autosurfaced.";
            showToastError(message);
        }
    )
};

const handleShowScanAnalyticsButtonClick = (roleId) => {
    window.open(`${process.env.REACT_APP_TURBO2_API}/roles/${roleId}/talent-scanned`);
};


const AddTalentSection = props => {
    const { role } = props;

    const tdSearch = role.td_search;
    const customerSearch = role.user_searches ? role.user_searches[0] : null;
    const searchLastUpdatedAt = tdSearch && customerSearch ?
        (new Date(tdSearch.updated_at) > new Date(customerSearch.updated_at) ? tdSearch.updated_at : customerSearch.updated_at) :
        tdSearch ? tdSearch.updated_at : customerSearch ? customerSearch.updated_at : null;

    const searchSource = tdSearch && customerSearch ?
        (new Date(tdSearch.updated_at) > new Date(customerSearch.updated_at) ? "TD" : "Customer") :
        tdSearch ? "TD" : customerSearch ? "Customer" : null;

    return (
        <FormGreyWrapper>
            <Row style={{ paddingBottom: '20px' }}>
                <Col xs={12}>
                    <Col xs={2}>
                        <div style={{ paddingBottom: '20px', fontSize: '23px' }}>AddTalent</div>
                    </Col>
                    
                    {props.hasAutoSurfacedTalent ?
                        <Col xs={10}>
                            <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <div style={{ display: 'flex' }}>
                                    {searchLastUpdatedAt && searchSource ?
                                        <div
                                            style={{
                                                color: '#838485',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            AI is using {searchSource}'s Search criteria, updated {moment.utc(searchLastUpdatedAt).local().format('MMM D, YYYY')}
                                        </div>
                                        : null}
                                    <div style={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: 2,
                                        background: '#147CA1',
                                        marginTop: 2,
                                        marginLeft: 12
                                    }}></div>
                                    <div style={{
                                        paddingBottom: '20px',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: 'normal',
                                        marginLeft: 10
                                    }}>Added by AI
                                    </div>
                                </div>
                            </Row>
                        </Col> : null
                    }
                    <Col xs={12} className={classes.AddTalentRowWrapper}>
                        {props.talent.map((talent, index) => {
                            return <TalentAddRow
                                key={index}
                                index={index}
                                talent={talent}
                                handleRemoveItemFromForm={props.handleRemoveItemFromForm}
                                handleChange={props.handleChange}
                                onBlur={props.handleOnBlur}
                                errors={props.errors}
                                touched={props.touched}
                                isSubmitting={props.isSubmitting}
                                handleBlur={props.handleBlur}
                                roleId={props.roleId}
                                />
                        })}
                    </Col>

                </Col>
            </Row>
            <Row>
                <div className={classes.ButtonsRow}>
                    <Col xs={4}>
                        <div
                            className={classes.AddTalentRow}
                            onClick={props.handleAddEmptyItemToForm}>
                            + Add one more talent
                        </div>
                    </Col>
                    <Col xs={4}>
                    <Button
                        className={classes.Buttons}
                        bsStyle="info"
                        block
                        onClick={() => handleTriggerAutosurfacedButtonClick(props.roleId)}>
                        Trigger autosurfaced
                    </Button>
                    </Col>
                    <Col xs={4}>
                    <Button
                        className={classes.Buttons}
                        bsStyle="info"
                        block
                        onClick={() => handleShowScanAnalyticsButtonClick(props.roleId)}>
                       Show Scan analytics
                    </Button>
                    </Col>
                    
                </div>
            </Row>
        </FormGreyWrapper>
    )
};

export default AddTalentSection;

AddTalentSection.propTypes = {
    handleAddEmptyItemToForm: PropTypes.func.isRequired,
    handleRemoveItemFromForm: PropTypes.func.isRequired
    // talent: PropTypes.array.isRequired,
    // addAnotherTalent: PropTypes.func.isRequired,
    // removeTalent: PropTypes.func.isRequired,
    // handleChange: PropTypes.func.isRequired,
    // linkChanged: PropTypes.func.isRequired,
    // genderChanged: PropTypes.func.isRequired,
    // diverseChanged: PropTypes.func.isRequired,
    // handleOnBlur: PropTypes.func.isRequired
};

AddTalentSection.defaultProps = {};